import { createSlice } from "@reduxjs/toolkit";

const checkboxFilterInitialState = {
    selectedCheckboxList: [],
    selectedCheckboxListForNewOpen: []
}

const TimeSheetFilterSlice = createSlice({
    name: "checkboxFilters",
    initialState: checkboxFilterInitialState,
    reducers: {
        setCheckboxList: (state, {payload}) => {
            state.selectedCheckboxList = payload;
        },
        setCheckboxListForNewOpen: (state, {payload}) => {
            state.selectedCheckboxListForNewOpen = payload;
        }
    }
})

export const {setCheckboxList, setCheckboxListForNewOpen} = TimeSheetFilterSlice.actions;
export const getAllCheckboxes = (state: any) => state.checkboxFilters.selectedCheckboxList;
export const getNewOpenCheckboxes = (state: any) => state.checkboxFilters.selectedCheckboxListForNewOpen;
export default TimeSheetFilterSlice.reducer;