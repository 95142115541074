import React, { useState } from "react";
import { TagButton, TagMobileSliderPopover, TagRadioButton, TagRadioButtons } from "@tag/tag-components-react-v3";
import { useDispatch, useSelector } from "react-redux";
import "../TimesheetFilter/TimesheetFilter.scss";
import { getAllRadioValue, setRadioList } from "../FilterRadioSlice";

export default function TimeSheetSortFilter(props: any) {
    const {  onDismiss } = props;
    //const [open, setOpen] = React.useState(false);
    const {radioAscFilter, radioValueFilter} = useSelector(getAllRadioValue);
    const dispatch = useDispatch();
    const [radioAscValue, setRadioAscValue] = useState(radioAscFilter);
    const [radioFilterValue, setRadioFilterValue] = useState(radioValueFilter);


    //on Apply button click store all values in redux
    const onSubmitRadio = () => {
        onDismiss();
        const payload: any = {
            radioAscFilter: radioAscValue ? radioAscValue : "Ascending", 
            radioValueFilter: radioFilterValue ? radioFilterValue : "date"
        }
        dispatch(setRadioList(payload));
    }
    
    return (
        <>
            <TagMobileSliderPopover
                open={true}
                className="customPopover"
                heading="Sort timesheets"
                initialHeight="450px"
                onClosed={() => onDismiss()}
            >
                <div className="FilterModal">
                    <div className="FilterModal-content">

                        <TagRadioButtons
                            accent="navy"
                            value={radioAscFilter ? radioAscFilter : "Ascending"}
                            name="sortradioBtn"
                            colourScheme="default"
                            device="mobile"
                            orientation="horizontal"
                            onRadioValueChange={(e: any) => setRadioAscValue(e.target.value)}
                        >
                            <TagRadioButton value="Ascending" checked={radioAscValue === "Ascending"}>Ascending</TagRadioButton>
                            <TagRadioButton value="Descending" checked={radioAscValue === "Descending"}>Descending</TagRadioButton>
                        </TagRadioButtons>

                        <div className="FilterModal-title mb20">Sort by</div>
                        <TagRadioButtons
                            accent="navy"
                            name="radioBtn"
                            colourScheme="default"
                            value={radioValueFilter ? radioValueFilter : "date"}
                            device="mobile"
                            orientation="vertical"
                            onRadioValueChange={(e: any) => setRadioFilterValue(e.target.value)}
                        >
                            <TagRadioButton
                                value="date"
                                checked={radioFilterValue === "date"}>
                                Timesheet modified date (Default)
                            </TagRadioButton>

                            <TagRadioButton
                                value="name"
                                checked={radioFilterValue === "name"}>
                                Worker name
                            </TagRadioButton>

                            <TagRadioButton 
                                value="entryDate" 
                                checked={radioFilterValue === "entryDate"}>
                                    Date submitted
                                </TagRadioButton>
                                
                            <TagRadioButton 
                                value="status" 
                                checked={radioFilterValue === "status"}>
                                    Status
                            </TagRadioButton>

                            <TagRadioButton value="byStartDate" checked={radioFilterValue === "byStartDate"}>
                                Timesheet start date
                            </TagRadioButton>
                        </TagRadioButtons>

                    </div>
                    <div className="FilterModal-footer">
                        <div className="FilterModal-footer-btnGrid">
                            <div className="FilterModal-footer-btn" onClick={() => onDismiss()}>
                                <TagButton
                                    className="customTernaryButton"
                                    text="Cancel"
                                    size="default"
                                    device="mobile">
                                </TagButton>
                            </div>

                            <div className="FilterModal-footer-btn FilterModal-footer-btn__filled " onClick={() => onSubmitRadio()}>
                                <TagButton
                                    device="mobile"
                                    text="Apply sorting"
                                    accent="navy"
                                    className="customButton"
                                ></TagButton>
                            </div>
                        </div>
                    </div>
                </div>
            </TagMobileSliderPopover>
        </>
    )
}
