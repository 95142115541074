import { Link } from "react-router-dom";
import "./TimesheetDetail.scss";
import {TagSkeleton} from "@tag/tag-components-react-v3";
export default function TimesheetDetailsSkeleton() {
	return (
		<>
			<div className="timeDetails_header">
				<div className="timeDetails_header_back"></div>
				<div className="timeDetails_header_title">
					<TagSkeleton width="150px" height="20px"></TagSkeleton>
				</div>
				<Link to="/timesheet-history">
					<div className="timeDetails_header_action">
						<TagSkeleton width="25px" height="25px"></TagSkeleton>
					</div>
				</Link>
				<Link to="/edit-timesheets">
					<div className="timeDetails_header_action">
						<TagSkeleton width="25px" height="25px"></TagSkeleton>
					</div>
				</Link>
			</div>

			<div className="timeDetails_content">
				<div className="timeDetails_content_grey">
					<div className="recruiter_card">
						<div className="recruiter_card_body">
							<div className="recruiter_card_body_name">
								<TagSkeleton width="84px" height="15px"></TagSkeleton>
								<span className="recruiter_card_body_status">
									<TagSkeleton width="110px" height="15px"></TagSkeleton>
								</span>
							</div>
							<p className="recruiter_card_body_dept">
								<TagSkeleton width="105px" height="18px"></TagSkeleton>
							</p>
							<p className="recruiter_card_body_date">
								<TagSkeleton width="163px" height="16px"></TagSkeleton>
							</p>
							<div className="recruiter_card_body_hours">
								<b>
									<TagSkeleton width="112px" height="16px"></TagSkeleton>
								</b>
								<p>
									<TagSkeleton width="33px" height="13px"></TagSkeleton>
								</p>
							</div>
						</div>
					</div>
				</div>
				<table className="timeDetails_content_table">
					<thead>
						<tr>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
							<th>
								<TagSkeleton width="50px" height="15px"></TagSkeleton>
							</th>
						</tr>
					</thead>
					<tbody>
						{Array(5)
							.fill(0)
							.map((key, index) => (
								<tr key={index}>
									<td data-label="Number">
										<div className="timeDetails_content_table_date"><TagSkeleton width="30px" height="20px" itemStyle={{ marginBottom: "5px" }}></TagSkeleton></div>
										<div className="timeDetails_content_table_day"><TagSkeleton width="40px" height="15px"></TagSkeleton></div>
									</td>
									<td data-label="Name">
										<TagSkeleton width="50px" height="15px"></TagSkeleton>
									</td>
									<td data-label="Phone">
										<TagSkeleton width="50px" height="15px"></TagSkeleton>
									</td>
									<td data-label="Date">
										<div className="timeDetails_content_table_total">
											<TagSkeleton width="50px" height="15px"></TagSkeleton>
										</div>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>

			<div className="timeDetails_footer">
				<div className="timeDetails_footer_total">
					<div className="timeDetails_footer_total_text">
						<TagSkeleton width="70px" height="15px"></TagSkeleton>
					</div>
					<div className="timeDetails_footer_total_text">
						<TagSkeleton width="70px" height="15px"></TagSkeleton>
					</div>
				</div>
				<div className="timeDetails_footer_btn_approve">
					<TagSkeleton width="100%" height="45px" className="customButton"></TagSkeleton>
				</div>
				<div className="timeDetails_footer_btn_query">
					<TagSkeleton width="100%" height="45px" className="customButton"></TagSkeleton>
				</div>
			</div>
		</>
	)
}
