import { createSlice } from "@reduxjs/toolkit";

const TimeSheetDetailInitialState = {
    timeSheetDetailName: "",
    timeSheetDetailStatus: "",
}

const TimeSheetDetailSlice = createSlice({
    name: "timeSheetSlice",
    initialState: TimeSheetDetailInitialState,
    reducers: {
        setTimeSheetDetailName: (state, {payload}) => {
            state.timeSheetDetailName = payload.timeSheetDetailName;
            state.timeSheetDetailStatus = payload.timeSheetDetailStatus;
        }
    }
})

export const {setTimeSheetDetailName} = TimeSheetDetailSlice.actions;
export const getAllTimeSheetDetails = (state: any) => state.timeSheetSlice;
export default TimeSheetDetailSlice.reducer;