import React from "react";
import { TagButton, TagCheckbox, TagMobileSliderPopover } from "@tag/tag-components-react-v3";
import { useDispatch, useSelector } from "react-redux";
import { setCheckboxList, getAllCheckboxes, setCheckboxListForNewOpen, getNewOpenCheckboxes } from "../../Timesheet/TimeSheetFilterSlice";
import "./TimesheetFilter.scss";

export default function TimeSheetFilter(props: any) {
    const { checkboxArray, onDismiss } = props;
    //const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const { selectedCheckboxList } = useSelector(getAllCheckboxes);
    const { selectedCheckboxListForNewOpen } = useSelector(getNewOpenCheckboxes);
    const [theAllowedActivityType, setTheAllowedActivityType] = React.useState<Array<string>>(selectedCheckboxList);
    const [
        theAllowedActivityTypeForNewOpen,
        setTheAllowedActivityTypeForNewOpen,
      ] = React.useState<Array<string>>(selectedCheckboxListForNewOpen);
    

    //get all selected checkbox values
    function checkActivityType(checkboxName?: string) {
        const theTempAllowedActivityType = Object.assign([], theAllowedActivityType);
        if (theTempAllowedActivityType.indexOf(checkboxName ?? " ") === -1) {
            theTempAllowedActivityType.push(checkboxName ?? " ");
        }
        else {
            theTempAllowedActivityType.splice(
                theTempAllowedActivityType.indexOf(checkboxName ?? " "),
                1
            );
        }
        setTheAllowedActivityType(theTempAllowedActivityType);
    }
    function checkActivityTypeForNewOpen(checkboxName?: string) {
        const theTempAllowedActivityTypeForNewOpen = Object.assign(
            [],
            theAllowedActivityTypeForNewOpen
        );
        if (
            theTempAllowedActivityTypeForNewOpen.indexOf(checkboxName ?? " ") === -1
        ) {
            theTempAllowedActivityTypeForNewOpen.push(checkboxName ?? " ");
        } else {
            theTempAllowedActivityTypeForNewOpen.splice(
                theTempAllowedActivityTypeForNewOpen.indexOf(checkboxName ?? " "),
                1
            );
        }
        setTheAllowedActivityTypeForNewOpen(theTempAllowedActivityTypeForNewOpen);
    }
    //on Apply button click store all values in redux
    const onSubmitCheckbox = () => {
        onDismiss();
        const payload: any = { selectedCheckboxList: theAllowedActivityType }
        const payloadForNewOpen: any = {
            selectedCheckboxListForNewOpen: theAllowedActivityTypeForNewOpen,
        };
        dispatch(setCheckboxList(payload));
        dispatch(setCheckboxListForNewOpen(payloadForNewOpen));
    }
    return (
        <>
            <TagMobileSliderPopover
                open={true}
                heading={"Filter"}
                initialHeight="580px"
                className="customPopover"
                onClosed={() => onDismiss()}>
                <div className="FilterModal">
                    <div className="FilterModal-content">
                        <span className="FilterModal-checkbox">
                            <TagCheckbox
                                rounded
                                id="new"
                                className="FilterModal-card-checkbox"
                                size="normal"
                                accent="navy"
                                name="New"
                                label="New"
                                colourScheme="default"
                                onCheckboxValueChange={(e: any) => checkActivityTypeForNewOpen("New")}
                                checked={theAllowedActivityTypeForNewOpen?.toString().includes("New")}
                            />
                        </span>
                        <span className="FilterModal-checkbox" style={{ "paddingLeft": "20px" }}>
                            <TagCheckbox
                                rounded
                                id="Open"
                                className="FilterModal-card-checkbox"
                                size="normal"
                                accent="navy"
                                name="Open"
                                label="Open"
                                colourScheme="default"
                                onCheckboxValueChange={(e: any) => checkActivityTypeForNewOpen("Open")}
                                checked={theAllowedActivityTypeForNewOpen?.toString().includes("Open")}
                            />
                        </span>
                        <div className="FilterModal-title mt20">Filter by Status</div>
                        {checkboxArray[0].map((item: any, index: any) => (
                            <div className="FilterModal-checkbox" key={index}>
                                <TagCheckbox
                                    rounded
                                    id={item.type}
                                    className="FilterModal-card-checkbox"
                                    size="normal"
                                    accent="navy"
                                    name={item.type}
                                    label={`${item.type} (${item.count})`}
                                    colourScheme="default"
                                    onCheckboxValueChange={(e: any) => checkActivityType(
                                        item.type
                                    )}
                                    checked={theAllowedActivityType?.toString().includes(
                                        item.type
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="FilterModal-footer">
                        <div className="FilterModal-footer-btnGrid">
                            <div className="FilterModal-footer-btn" onClick={() => onDismiss()}>
                                <TagButton
                                    className="customTernaryButton"
                                    text="Cancel"
                                    size="default"
                                    device="mobile">
                                </TagButton>
                            </div>

                            <div className="FilterModal-footer-btn FilterModal-footer-btn__filled " onClick={() => onSubmitCheckbox()}>
                                <TagButton
                                    device="mobile"
                                    text="Apply filter"
                                    accent="navy"
                                    className="customButton"
                                ></TagButton>
                            </div>
                        </div>
                    </div>
                </div>
            </TagMobileSliderPopover>
        </>
    )
}
