import { useState } from "react";

export const useLocalStorage = (key: string, initialValue: any) => {
    // pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
      if (typeof window === "undefined")
        return initialValue;

      try {
        // get value from local storage by key
        const item = window.localStorage.getItem(key);
        // parse stored item or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // on error also return initialValue
        console.error(error);
        return initialValue;
      }
    });

    // wrapped version of useState's setter function that persists the new value to localStorage.
    const setValue = (value: any) => {
      try {
        // value may be a function to replicate same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        // save to state
        setStoredValue(valueToStore);

        // save to local storage
        if (typeof window !== "undefined")
          window.localStorage.setItem(key, JSON.stringify(valueToStore));

      } catch (error) {
        // toDo: do something with the error?
        console.error(error);
      }
    };
    
    return [storedValue, setValue];
}