import { TagMobileModal, TagMobileSliderPopover, TagSimpleButton, TagTertiaryButton } from "@tag/tag-components-react-v3"
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { containerVariants } from "../../types/constant";
import iconTrash from "../../images/iconTrash.svg";
import iconCancel from "../../images/iconCancel.svg";
import iconTimecardActive from "../../images/iconTimecardActive.svg";
import iconMore from "../../images/iconMore.svg";
import iconTimeMachine2 from "../../images/iconTimeMachine2.svg";
import PageFooter from "../PageFooter/PageFooter";
import "./Notifications.scss";
import iconCoffee from "../../images/iconCoffee.svg";
import NotificationsSkeleton from "./NotificationsSkeleton";
import {  deleteNotificationFunction, deleteSingleNotificationFunction, getAllNotifications, getNotification } from "../../api/api";
import DOMPurify from "dompurify";

export default function Notifications() {
    const [openDeleteSlider, setOpenDeleteSlider] = React.useState(false);
    const [deleteNotification, setDeleteNotification] = React.useState(false);
    const [_removeAllNotification, setRemoveAllNotification] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [httpStatusCode, setHttpStatusCode] = React.useState(0);
    const [getNotifications, setNotifications] = useState<any[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [notificationId, setNotificationId] = React.useState<any[]>([]);

    useEffect(() => {
        getApi();
        if(httpStatusCode === 0){
            setLoading(false);
        }
    }, []);


    async function getApi() {
        try {
            const res = await getAllNotifications()
            if (Object.keys(res.data).length && res.status === 200) {
                // received api data successfully
                
                if (res.data) {
                    setLoading(false);
                }
                setNotifications(res.data);
                setHttpStatusCode(res.status);
                
              }
            else{
                setLoading(false);
                setNotifications([]);
                setHttpStatusCode(res.status);
            }
            
        } catch (err) {
            console.log("err:", err);
            return [];
        }
    }
    
    
    //Fetch status from data
	const allNotificationIds: any[] = [];
    let allNewNotificationIds: any[] = [];
    let allEarlierNotificationIds: any[] = [];
    const allIds = getNotifications?.map((dataItem) => dataItem.id) // get all status
	if (getNotifications) {
        for(var i of allIds) {
		   allNotificationIds.push(allIds[i]);
        }
        allNewNotificationIds = getNotifications.filter((dataItem) => dataItem.isNew === true) // get all notification with NEW status
        allEarlierNotificationIds = getNotifications.filter((dataItem) => dataItem.isNew === false) // get all notification with EARLIER status
    }


    const deleteSingleNotification = (notificationIdValue: number) => {
        setDeleteNotification(true);
        setOpenDeleteSlider(true);
        setNotificationId([notificationIdValue]);
    }
    const closeDeleteSlider = () => {
        setDeleteNotification(false);
        setOpenDeleteSlider(false);
    }
    const openDeleteModalFunction = () => {
        setOpenDeleteModal(true);
    }
    
    const deleteAllNotification = async () => {
        setOpenDeleteModal(false);
        setOpenDeleteSlider(false);
        setRemoveAllNotification(true);

        const res = await deleteNotificationFunction(allIds);
        
        if(res.status === 200){
            await getApi();
        }
    }
    const onCloseModal = () => {
        setDeleteNotification(false);
        setOpenDeleteModal(false);
        setOpenDeleteSlider(false);
    }
    const onDeleteSingleNotification = async () => {
        setDeleteNotification(false);
        setOpenDeleteSlider(false);
        setOpenDeleteModal(false);
        const res = await deleteSingleNotificationFunction(notificationId);
        if(res.status === 200){
            getApi();
        }
    }
    const sanitizedData = (data: any) => ({
        __html: DOMPurify.sanitize(data)
    })
    
    return (
        <>
            <motion.div
                className="motionDiv"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={containerVariants}
            >
                {loading ? <NotificationsSkeleton /> :
                    <>
                        <div className="notification_header">
                            <div className="notification_header_title"  data-testid="notificationTitle">
                                Notifications
                            </div>
                            <div className="notification_header_action">
                                {allNotificationIds.length > 0 ? <img src={iconCancel} className="notification_action_size" alt={iconCancel} onClick={() => setOpenDeleteSlider(true)} /> : "" }
                            </div>
                        </div>

                        <div className="notification_content">
                            {allNotificationIds.length > 0 ? (<>
                                {allNewNotificationIds.length > 0 ?  <div className="notification_content-pageTitle" data-testid="newHeadingTitle">New</div>: "" }
                                {getNotifications.filter(item => item.isNew === true).map((item, index) => {
                                    return (
                                        <div className="notification_card" key={index}>
                                            <div className="notification_card-icon">
                                                <div className="notification_card-iconBg">
                                                    <img src={item.setIcon === "timesheet" ? iconTimecardActive : iconTimeMachine2} alt="Add icon" className="pageFooter-icon__size" />
                                                </div>
                                            </div>
                                            <div className="notification_card-title">
                                                <span>{item.username}</span>
                                                <span dangerouslySetInnerHTML={
                                                    sanitizedData(item.message)
                                                }></span>
                                            </div>
                                            <div className="notification_card-date">
                                                <div className="notification_card-dateText">{item.date}</div>
                                                <div className="notification_card-dots" onClick={() => deleteSingleNotification(item.id)}><img src={iconMore} alt="icon more" /></div>
                                            </div>
                                        </div>
                                    )
                                })}

                                {allNewNotificationIds.length > 0 && allEarlierNotificationIds.length > 0 ?  <div className="notification_content-pageTitle"  data-testid="earlierHeadingTitle">Earlier</div>: "" }
                                
                                {getNotifications.filter(item => item.isNew === false).map((item, index) => {
                                    return (
                                        <div className="notification_card" key={index}>
                                            <div className="notification_card-icon">
                                                <div className="notification_card-iconBg">
                                                    <img src={item.setIcon === "timesheet" ? iconTimecardActive : iconTimeMachine2} alt="Add icon" className="pageFooter-icon__size" />
                                                </div>
                                            </div>
                                            <div className="notification_card-title">
                                                <span>{item.username}</span> 
                                                <span dangerouslySetInnerHTML={
                                                    sanitizedData(item.message)
                                                }></span>
                                            </div>
                                            <div className="notification_card-date">
                                                <div className="notification_card-dateText">{item.date}</div>
                                                <div className="notification_card-dots" onClick={() => deleteSingleNotification(item.id)}><img src={iconMore} alt="icon more" /></div>
                                            </div>
                                        </div>
                                    )
                                })} </>)
                                : httpStatusCode === 0
                                    ? <section className="timeSheet-blankState">
                                        <div className="timeSheet-blankState__icon timeSheet-blankState__icon-green"><img src={iconCoffee} alt="coffee icon" /></div>
                                        <div className="timeSheet-blankState__heading">Something went wrong</div>
                                        <div className="timeSheet-blankState__description">Currently we are facing some issues. Please Try again after sometime.</div>
                                    </section>
                                    : <section className="timeSheet-blankState">
                                        <div className="timeSheet-blankState__icon timeSheet-blankState__icon-green"><img src={iconCoffee} alt="coffee icon" /></div>
                                        <div className="timeSheet-blankState__heading">Nothing new here!</div>
                                        <div className="timeSheet-blankState__subHeading">Time to enjoy a nice cup of coffee or tea.</div>
                                        <div className="timeSheet-blankState__description">Don’t forget all existing notifications can be found here. You can delete them if you"d like</div>
                                    </section>
                            }
                        </div>
                        <PageFooter />
                    </>
                }
            </motion.div>
            <TagMobileSliderPopover
                open={openDeleteSlider}
                className="queryPopover"
                initialHeight="100px"
                colour-scheme="light"
                onClosed={() => closeDeleteSlider()}
            >
                <div className="deleteNotification" onClick={() => openDeleteModalFunction()}>
                    <div className="deleteNotification-icon">
                        <img src={iconTrash} alt="Add icon" className="pageFooter-icon__size" />
                    </div>
                    <div className="deleteNotification-text">
                        {deleteNotification
                            ? <div className="deleteNotification-title">
                                Delete <span>Delete this notification</span>
                            </div>
                            : <div className="deleteNotification-title">
                                Delete all <span>Delete all notifications</span>
                            </div>}
                    </div>
                </div>
            </TagMobileSliderPopover>
            <TagMobileModal
                open={openDeleteModal}
                onOpenChange={(e) => setOpenDeleteModal(e.detail.open)}
                icon="Warning"
                iconAccent="coral"
                heading="Warning"
                colour-scheme="light"
                className="modalWidth_custom">
                <div className="dialog-content">
                    {deleteNotification
                        ? "You are about to delete this notification."
                        : "You are about to delete all notifications."
                    }
                    <div className="dialog-btn-group">
                        <TagSimpleButton accent="coral" device="mobile" onButtonClick={() => { deleteNotification ? onDeleteSingleNotification() : deleteAllNotification() }}>
                            {deleteNotification
                                ? <span>Delete this notification</span>
                                : <span>Delete all notifications</span>
                            }
                        </TagSimpleButton>
                        <TagTertiaryButton accent="title" device="mobile" onButtonClick={() => onCloseModal()} buttonStyle={{ "border": "0" }}>
                            Cancel
                        </TagTertiaryButton>
                    </div>
                </div>
            </TagMobileModal>
        </>
    )
}
