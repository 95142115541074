import React,{ useState } from "react";
import "./AddTimeEntry.scss";
import iconCalendar from "../../images/iconCalendar.svg";
import iconPlus from "../../images/iconPlus.svg";
import iconMinus from "../../images/iconMinus.svg";
import iconRemove from "../../images/iconRemove.svg";

import { TagButton,TagMobileAlert,TagMobileSliderPopover,} from "@tag/tag-components-react-v3";
type Task = {
  	id: number;
  	task: string;
}

let totalTime = 0;
let id = 0;
export default function AddTimeEntry(props: {
  isEdit?: boolean
  onDismiss: () => void
}) {
	const { isEdit, onDismiss } = props;
	const [addClick, removeClick] = useState(true);
	const [isBack, setIsBack] = useState(true);
	const [isCustom, setIsCustom] = useState(true);
	const [input, setInput] = useState<string>("");
	const [list, setList] = useState<Task[]>([]);
	const [showList, setShowList] = useState("timeEntryForm-showlists");
	const [showToast, setShowToast] = React.useState(false);

	const updateStatus = (status: string) => {
		const time = totalTime + parseInt(status);
		if (time <= 480) {
			const task: Task = { id: id, task: status };
			setList([...list, task]);
			id++;
			setShowList("timeEntryForm-breaks-lists");
			totalTime = totalTime + parseInt(status);
		} else {
			setShowToast(true);
		}
	};

	const removeTask = (id: number): void => {
		const newList: Task[] = list.filter((_, index) => index !== id);
		totalTime = totalTime - parseInt(list[id].task);
		setList(newList);
	};

	function addBreak() {
		setIsCustom(true);
		removeClick(!addClick);
		id++;
	}

	function cancelCustom() {
		setIsCustom(true);
		removeClick(false);
		setInput("");
	}

	function customClickable() {
		setIsCustom(!isCustom);
	}

	function addCustomBreak() {
		const time = totalTime + parseInt(input);
		if (time <= 480) {
			setIsBack(!isBack);
			const task: Task = { id: id, task: input };
			setList([...list, task]);
			setIsCustom(true);
			removeClick(true);
			id++;
			setShowList("timeEntryForm-breaks-lists");
			totalTime = totalTime + parseInt(input);
			setInput("");
		} else {
			setShowToast(true);
		}
	}
	
	return (
		<>
			<TagMobileSliderPopover
				open={true}
				heading={!isEdit ? "Edit time entry" : "Add time query"}
				initialHeight="80%"
				className="addtimeentry customPopover"
				onClosed={() => onDismiss()}
			>
				<div className="timeEntryForm">
					<div className="timeEntryForm-content">
						<div className="timeEntryForm-label">Date</div>
						<div className="timeEntryForm-inputControl">
							<div className="timeEntryForm-input">
								<input
									type="text"
									placeholder=""
									value="22/11/2021"
									className="timeEntryForm-inputBox"
								/>
								<span className="timeEntryForm-inputIcon">
									<img src={iconCalendar} alt="Calendar Icon" />
								</span>
							</div>
						</div>

						<div className="timeEntryForm-label">Start Time</div>
						<div className="timeEntryForm-inputControl">
							<div className="timeEntryForm-input">
								<input
									type="text"
									value="09:00"
									className="timeEntryForm-inputBox  text-center"
								/>
								<span className="timeEntryForm-actionIcon timeEntryForm-plusIcon">
									<img src={iconPlus} alt="Plus Icon" />
								</span>
								<span className="timeEntryForm-actionIcon">
									<img src={iconMinus} alt="Minus Icon" />
								</span>
							</div>
						</div>

						<div className="timeEntryForm-label">End Time</div>
						<div className="timeEntryForm-inputControl">
							<div className="timeEntryForm-input">
								<input
									type="text"
									value="15:30"
									className="timeEntryForm-inputBox  text-center"
								/>
								<span className="timeEntryForm-actionIcon timeEntryForm-plusIcon">
									<img src={iconPlus} alt="Plus Icon" />
								</span>
								<span className="timeEntryForm-actionIcon">
									<img src={iconMinus} alt="Minus Icon" />
								</span>
							</div>
						</div>

						<div className="timeEntryForm-label">Breaks</div>
						<div className="timeEntryForm-inputControl">
							{addClick ? (
								<div>
									<div className={showList}>
										<ul className="timeEntryForm-breaks-lists">
											{list &&
												list.map((task: Task, index) => (
													<li key={index}>
													
														{task.task} {""}
														{parseInt(task.task) > 1 ?" mins" : "min"}{""}
														<button onClick={() => removeTask(index)}><img src={iconRemove} alt="Profile img" /></button>
													</li>
												))}
										</ul>
									</div>

									<div
										className="timeEntryForm-buttons"
										onClick={() => {
											addBreak();
										}}
									>
										<div>
											<TagButton
												device="mobile"
												text="Add Break"
												accent="navy"
												className="customButtonNew"
											></TagButton>
										</div>
									</div>
								</div>
							) : (
								<div className="timeEntryForm-buttons">
									<div className="timeEntryForm-breaks">
										{isCustom ? (
											<div>
												<div className="timeEntryForm-breaks-list">
													<div className={showList}>
														<ul className="timeEntryForm-breaks-lists">
															{list &&
																list.map((task: Task, index) => (
																	<li key={index}>
																		{/* {task.task} mins */}
																		{task.task} {""}
																		{parseInt(task.task) > 1 ?" mins" : "min"}{""}
																		<button onClick={() => removeTask(index)}>
																			<img src={iconRemove} alt="Profile img" />
																		</button>
																	</li>
																))}
														</ul>
													</div>
												</div>
												<div className="timeEntryForm-breaks-list">
													<div className="timeEntryForm-breaks-list-lists">
														<div
															className="timeEntryForm-breaks-list-lists-box"
															onClick={() => updateStatus("15")}
														>
															<span>15 min</span>
														</div>
														<div
															className="timeEntryForm-breaks-list-lists-box"
															onClick={() => updateStatus("30")}
														>
															<span>30 min</span>
														</div>
														<div
															className="timeEntryForm-breaks-list-lists-box"
															onClick={() => updateStatus("60")}
														>
															<span>60 min</span>
														</div>

														<div
															className="timeEntryForm-breaks-list-lists-box"
															onClick={() => {
																customClickable();
															}}
														>
															<span>Custom</span>
														</div>
													</div>
												</div>
												<div
													onClick={() => {
														addBreak();
													}}
												>
													<TagButton
														className="customTernaryButton"
														text="Cancel Break"
														size="default"
														device="mobile"
													></TagButton>
												</div>
											</div>
										) : (
											<div className="timeEntryForm-input">
												<div>
													<input
														type="number"
														placeholder="Enter break minutes"
														className="timeEntryForm-inputBox timeEntryForm-break"
														value={input}
														onChange={(e) => {
															const regx = e.target.value.replace(
																/[\.]/g,
																'',
															)
															if (
																regx.length === 4 ||
																parseInt(e.target.value) == 0
															)
																return false //limits to 2 digit entry
															//saving input to state
															setInput(regx)
														}}
														required
													/>
													<span className="timeEntryForm-minutestxt">Minutes</span>
													<span
														className="timeEntryForm-plusIcon"
														onClick={() => {
															addCustomBreak();
														}}
													>
														<button
															disabled={!input}
															className="timeEntryForm-actionIcon timeEntryForm-plusIcon-text"
														>Add Break
														</button>
													</span>
												</div>
												<div
													onClick={() => {
														cancelCustom();
													}}
												>
													<TagButton
														className="customTernaryButton"
														text="Cancel Break"
														size="default"
														device="mobile"
													></TagButton>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="timeEntryForm-footer">
						<div className="timeEntryForm-footer__total">
							<div className="timeEntryForm-footer__total-text">Total</div>
							<div className="timeEntryForm-footer__total-text">7:30</div>
						</div>

						<div className="timeEntryForm-footer-btnGrid">
							<div
								className="timeEntryForm-footer-btn"
								onClick={() => onDismiss()}
							>
								<TagButton
									className="customTernaryButton"
									text="Cancel"
									size="default"
									device="mobile"
								></TagButton>
							</div>

							<div
								className="timeEntryForm-footer-btn timeEntryForm-footer-btn__filled "
								onClick={() => onDismiss()}
							>
								<TagButton
									device="mobile"
									text="Edit entry"
									accent="navy"
									className="customButton"
								></TagButton>
							</div>
						</div>
					</div>
				</div>
			</TagMobileSliderPopover>
			<div className={showToast ? "z-indexed" : ""}>
				{showToast ? (
                    <>
                        <TagMobileAlert
                            className="custom_alert"
                            size="fullWidth"
							showDuration={0}
							closeable
							closeTransition='slideDown'
                        >
                            <div className="div_alert">
                                <span className="span_alert">
									Limit Exceeds (Limit=480 mins)
                                </span>
                            </div>
                        </TagMobileAlert>
                    </>
                ) : null}
			</div>
		</>
	);
}
